<template>
  <CSidebar
    fixed
    :show="show"
    :minimize="minimize"
    @update:show="$emit('toggle-menu')"
  >
    <CSidebarBrand class="d-md-down-none">
      <router-link to="/">
        <img
          v-if="isMenuOpened && whiteLogo"
          :src="whiteLogo.url"
          :alt="whiteLogo.custom ? whiteLogo.custom.title : ''"
          :style="`max-height: ${minimize ? 20 : 30}px`"
        />
        <img
          v-else-if="!isMenuOpened && whiteLogoReduced"
          :src="whiteLogoReduced.url"
          :alt="whiteLogoReduced.custom ? whiteLogoReduced.custom.title : ''"
          :style="`max-height: ${minimize ? 20 : 30}px`"
        />
        <img v-else src="@/assets/img/logo-white.svg" alt="ow-logo-black" />
      </router-link>
    </CSidebarBrand>
    <div
      class="sidebar-search"
      :style="`display: ${minimize ? 'none' : 'block'}`"
    >
      <div class="input-group">
        <input v-model="search" placeholder="Digite aqui para pesquisar..." />
      </div>
    </div>
    <CRenderFunction flat :content-to-render="filteredItems" />
    <CSidebarMinimizer @click.native="toggleMinimize"> </CSidebarMinimizer>
    <span
      v-if="version"
      class="sidebar-version"
      :style="`opacity: ${minimize ? '0' : '1'}; left: ${
        minimize ? '-100%' : '50%'
      }`"
    >
      <a href="/about">About OW</a>
    </span>
  </CSidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import { normalizedLowerCase } from '@/utils'

/**
 * Core UI Sidebar menu with search box and minimize option
 * @see https://coreui.io/vue/docs/components/template-components.html#csidebar-api
 */
export default {
  props: {
    /**
     * Array of links for build the sidemenu structure
     */
    items: {
      type: Array,
      default: () => []
    },

    /**
     * Show sidemenu
     */
    show: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      search: '',
      isCurrentPage: false,
      minimize: false,
      version: '',
      isMenuOpened: true,
      allowedLinks: [{ _name: 'CSidebarNav', _children: [] }]
    }
  },

  computed: {
    ...mapGetters('auth', ['permissions']),
    ...mapGetters('settings', ['whiteLogo', 'whiteLogoReduced', 'settings']),

    /**
     * Search input by name in parent objects first
     * if no results, searchs in child objects if any
     * if search data is empty no search is made
     */
    filteredItems() {
      if (!this.search) return this.allowedLinks
      const search = normalizedLowerCase(this.search)

      const getChildren = (data) => {
        return data.filter((item) => {
          const found = !!~normalizedLowerCase(item.name).search(search)
          const hasChild = !!item.items && !!item.items.length
          if (hasChild) item.items = getChildren(item.items)
          return found || (!!item.items && !!item.items.length)
        })
      }

      const children = getChildren(cloneDeep(this.allowedLinks[0]._children))
      return [{ _name: 'CSidebarNav', _children: children }]
    }
  },

  watch: {
    permissions: {
      handler: 'getAllowedMenuLinks',
      deep: true
    },
    settings: {
      handler: 'getAllowedMenuLinks',
      deep: true
    }
  },

  mounted() {
    this.getVersion()
    this.handlerWindowSize()
    this.getAllowedMenuLinks()
  },

  methods: {
    getAllowedMenuLinks() {
      const getChildren = (data, settings) => {
        const hasPermission = data.filter((item) => {
          if (item.permission) {
            if (this.permissions && this.permissions.includes(item.permission))
              return true
          } else {
            if (item.items && item.items.length) {
              const children = [...item.items]
              item.items = getChildren(children, settings)
            }
            return true
          }
          return false
        })

        const isValid = hasPermission.filter((item) => {
          if (item.validation) {
            if (item.validation && item.validation({ settings })) {
              return true
            }
          } else {
            if (item.items && item.items.length) {
              const children = [...item.items]
              item.items = getChildren(children, settings)
            }
            return true
          }
          return false
        })

        return isValid
      }

      const settings = this.settings
      const items = cloneDeep(this.items[0]._children)
      const children = getChildren(items, settings)

      this.allowedLinks = [{ _name: 'CSidebarNav', _children: children }]

      // CASO EXISTA SUB ROTA A REMOVER USAR O EXEMPLO ABAIXO
      // Obs: O código acima contempla sub rotas, porém vou deixar o exemplo anterior
      // if (this.permissions.isArray()) {
      //   this.allowedLinks[0]._children.map(item => {
      //     let allowedSubItems = []
      //     if (item.items) {
      //       allowedSubItems = item.items.filter((subItem) => {
      //         if (subItem.to === '/404') {
      //           if (this.permissions.includes('manage_404')) {
      //             return true
      //           } else {
      //             return false
      //           }
      //         }
      //         return true
      //       })
      //     }
      //     item.items = allowedSubItems
      //   })
      // }
    },
    /**
     * Get VUE app version
     */
    getVersion() {
      this.version = process.env.VUE_APP_VERSION
    },

    /**
     * Switch between minimize state of Sidebar.
     * @see https://coreui.io/vue/docs/components/template-components.html#csidebar-api
     * @public
     */
    toggleMinimize: function () {
      this.minimize = !this.minimize
      document.body.classList.toggle('sidebarIsOpen')
      this.isMenuOpened = !this.isMenuOpened
    },
    /**
     * Verify and sets size of Pagination component
     */
    handlerWindowSize() {
      const handler = () => {
        if (window.innerWidth <= 992) {
          // add class to be used as flag on loading
          document.body.classList.remove('sidebarIsOpen')
        } else {
          document.body.classList.add('sidebarIsOpen')
        }
      }

      window.addEventListener('resize', () => {
        handler()
      })

      handler()
    }
  }
}
</script>

<style lang="scss" scoped>
.currentPageBorder {
  border-left: 2px solid $danger-dark;
}

.sidebar-version {
  position: absolute;
  bottom: 0px;
  height: 50px;
  line-height: 50px;
  // z-index: -1;
  color: $white;
  transform: translateX(-50%);
  transition: 300ms ease;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.sidebar-search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 0.75rem 1rem;
  transition: 300ms ease;

  .input-group {
    input {
      border: 0px;
      border-bottom: 1px solid $white;
      min-height: 40px;
      height: 40px;
      font-size: 14px;
      padding: 10px 5px;
      width: 100%;
      background-color: $gray-base;
      outline: none;
      color: $white;

      &::placeholder {
        color: $white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        color: $white;
      }

      &::-ms-input-placeholder {
        color: $white;
      }
    }

    .btn-outline-secondary {
      border-color: rgba(94, 101, 117, 0.32);
    }
  }
}
</style>
