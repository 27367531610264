<template>
  <CDropdown
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink v-if="userLogged">
        Olá, {{ userLogged.name }}
        <i class="fas fa-chevron-down ml-2 mt-1"></i>
      </CHeaderNavLink>
    </template>
    <CDropdownItem :to="`/users/edit/${userLogged.id}`">
      <CIcon name="cil-user" class="mr-2" />
      Meu Perfil
    </CDropdownItem>
    <CDropdownItem to="/settings">
      <CIcon name="cil-settings" class="mr-2" />
      Configurações
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-account-logout" class="mr-2" />
      Sair
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex'

import Cookies from 'js-cookie'

export default {
  data() {
    return {
      userLogged: {}
    }
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  async created() {
    const userData = Cookies.getJSON(`${process.env.VUE_APP_COOKIES_PREFIX}user`)
    this.userLogged = userData
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      window.location.href = '/login'
    }
  }
}
</script>

<style lang="scss">
.c-icon {
  margin-right: 0.3rem;
}

.c-header-nav-items {
  .dropdown-menu {
    padding-bottom: 0px;
  }

  .dropdown-item {
    padding: 1rem 1.25rem;
  }
}
</style>
