// Para procurar por um novo icone, é necessário entrar no site abaixo e filtrar
// apenas pelo icones "free"
// https://icons.coreui.io/icons/
export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Usuários',
        to: '/users',
        icon: 'cil-user'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Banners',
        to: '/banners',
        icon: 'cil-image'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Notícias',
        icon: 'cil-newspaper',
        items: [
          {
            name: 'Categorias',
            to: '/news-category'
          },
          {
            name: 'Notícias',
            to: '/news'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Newsletters',
        to: '/newsletters',
        icon: 'cil-paper-plane'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Contatos',
        to: '/contacts',
        icon: 'cil-envelope-letter'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'SEO',
        to: '/seo',
        icon: 'cil-chart'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Cookies',
        to: '/cookies',
        icon: 'cil-av-timer',
        permission: 'manage_cookies',
        validation: ({ settings }) =>
          settings.cookies_notification_version == 'complete'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Styleguide',
        to: '/styleguide',
        icon: 'cil-line-style',
        badge: {
          color: 'success',
          text: 'Novo'
        }
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Outras páginas',
        icon: 'cil-star',
        items: [
          {
            name: 'Página 502',
            to: '/500'
          },
          {
            name: 'Página 404',
            to: '/404'
          }
        ]
      }
    ]
  }
]
