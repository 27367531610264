<template>
  <CHeader fixed with-subheader light>
    <div class="d-flex justify-content-between w-100">
      <CToggler in-header class="py-3 ml-3" @click="$emit('toggle-menu')" />

      <CHeaderNav class="mr-4 float-right">
        <!-- Dashboard -->
        <CHeaderNavItem class="px-2">
          <CHeaderNavLink to="/dashboard">
            <CIcon
              v-c-tooltip="'Dashboard'"
              name="cil-speedometer"
              class="dashboard-icon"
            />
          </CHeaderNavLink>
        </CHeaderNavItem>

        <!-- Site -->
        <CHeaderNavItem class="px-2">
          <CHeaderNavLink :href="siteUrl" target="_blank">
            <CIcon
              v-c-tooltip="'Ir para o site'"
              class="go-to-site"
              name="cil-home"
            />
          </CHeaderNavLink>
        </CHeaderNavItem>

        <!-- Usuários -->
        <CHeaderNavItem v-if="allowedButtons.users" class="px-2">
          <CHeaderNavLink to="/users">
            <CIcon v-c-tooltip="'Usuários'" name="cil-people" />
          </CHeaderNavLink>
        </CHeaderNavItem>

        <!-- Permissões -->
        <CHeaderNavItem v-if="allowedButtons.permissions" class="px-2">
          <CHeaderNavLink to="/permissions">
            <CIcon v-c-tooltip="'Permissões'" name="cil-shield-alt" />
          </CHeaderNavLink>
        </CHeaderNavItem>

        <!-- Configurações -->
        <CHeaderNavItem v-if="allowedButtons.settings" class="px-2">
          <CHeaderNavLink to="/settings">
            <CIcon v-c-tooltip="'Configurações'" name="cil-settings" />
          </CHeaderNavLink>
        </CHeaderNavItem>

        <!-- Divider -->
        <div class="c-header-nav-item-divider d-sm-down-none"></div>

        <!-- Dropdown -->
        <HeaderDropdown />
      </CHeaderNav>
    </div>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import HeaderDropdown from '@/components/layout/HeaderDropdown'

import { mapGetters } from 'vuex'

export default {
  components: {
    HeaderDropdown
  },

  data() {
    return {
      allowedButtons: {
        permissions: false,
        users: false,
        settings: false
      },
      siteUrl: process.env.VUE_APP_SITE_URL
    }
  },

  computed: {
    ...mapGetters('auth', ['permissions'])
  },

  watch: {
    permissions: {
      handler() {
        this.setAllowedButtons()
      },
      deep: true
    }
  },

  created() {
    this.setAllowedButtons()
  },

  methods: {
    setAllowedButtons() {
      if (this.permissions.includes('manage_permissions')) {
        this.allowedButtons.permissions = true
      } else {
        this.allowedButtons.permissions = false
      }
      if (this.permissions.includes('manage_users')) {
        this.allowedButtons.users = true
      } else {
        this.allowedButtons.users = false
      }
      if (this.permissions.includes('manage_settings')) {
        this.allowedButtons.settings = true
      } else {
        this.allowedButtons.settings = false
      }
    }
  }
}
</script>

<style lang="scss">
.c-header {
  border-bottom: none !important;

  .c-subheader {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .c-header-nav-item-divider {
    display: inline-block;
    background: #d8dbe0;
    width: 1px;
    padding: 15px 0px;
    margin: 0px 10px 0px 0px;
  }

  .dashboard-icon {
    margin-top: 2px !important;
  }
}

.go-to-site + .tooltip-old .tooltip-old-inner {
  width: 90px;
}
</style>
